<template>
  <div id="bubble--3" class="bubble" ref="bubble">
    <div class="bubble__wrapper">
      <div class="bubble__content">
        <div id="bubble__b3" class="bubble__background"></div>
        <div class="bubble__text">
          Wie bleibe ich <br />
          langfristig am Markt <br />
          erfolgreich?
          <span>
            <img
              class="bubble__icon"
              :src="getPath('assets/img/sdd/bubbleicons/4.png')"
            />
            <img
              class="bubble__icon"
              :src="getPath('assets/img/sdd/bubbleicons/5.png')"
            />
          </span>
        </div>
      </div>
      <BubbleClock class="bubble__clock bubble__clock--left" ref="bubble" />
    </div>
  </div>
</template>

<script>
import BubbleClock from "./BubbleClock";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);
import getPathFunction from "@/utilities/composition/getPathFunction";

import { onMounted, ref, watchEffect } from "vue";

export default {
  components: { BubbleClock },
  props: { startbubble: Boolean },
  setup(props) {
    const { getPath } = getPathFunction();
    const bubble = ref(null);

    let tl = gsap.timeline({ paused: true });
    let bg = null;
    let text = null;
    let icons = null;
    let clock = null;
    let bubbleWrapper = null;

    function startAnimation() {
      // console.log("startAnimation!");
      tl.timeScale(1).play();
    }
    function resetAnimation() {
      // console.log("resetAnimation");
      // tl.timeScale(2).reverse();
    }

    onMounted(() => {
      bg = bubble.value.querySelector(".bubble__background");
      bubbleWrapper = bubble.value.querySelector(".bubble__wrapper");
      text = new SplitText(bubble.value.querySelectorAll(".bubble__text"), {
        type: "words,chars",
        wordsClass: "bubble__words",
      });

      icons = bubble.value.querySelectorAll(".bubble__icon");
      clock = bubble.value.querySelectorAll(".bubble__clock");
      // Setting
      tl.pause();
      tl.set(clock, { opacity: 0 });
      tl.set(bg, { scale: 0, ease: "elastic.out(1, 0.3)" });
      tl.set(text.chars, { opacity: 0 });
      tl.set(icons, {
        scale: 0,
        ease: "elastic.out(1, 0.3)",
      });
      tl.set(clock, { opacity: 0 });

      // Tweening
      tl.to(bg, { duration: 1, scale: 1, ease: "elastic.out(1, 0.3)" }, 0);
      tl.to(text.chars, { duration: 0.02, opacity: 1, stagger: 0.05 }, 0.5);
      tl.to(icons, {
        duration: 1,
        scale: 1,
        ease: "elastic.out(1, 0.3)",
        stagger: 0.2,
      });
      tl.to(clock, { duration: 2, opacity: 1 }, 0);
      tl.set(bubbleWrapper, { opacity: 1 }, 0);
    });
    watchEffect(() => {
      if (props.startbubble) {
        startAnimation();
      } else {
        resetAnimation();
      }
    });

    return {
      getPath,
      bubble,
    };
  },
};
</script>

<style lang="scss"></style>
