<template>
  <div class="sdd__section" ref="scrollContainer">
    <BubbleThree :startbubble="startAnimate" class="sdd__bubble" />
    <div class="sdd__wrapper">
      <!-- Generator: Adobe Illustrator 26.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg
        version="1.1"
        class="sdd__svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1103 142.1"
        style="enable-background:new 0 0 1103 142.1;"
        xml:space="preserve"
      >
        <g transform="translate(-349 -2421.096)" class="st0">
          <path
            class="sdd__path"
            d="M517.5,2424.9h53.8c10.5,0,20.2,1.8,29.2,5.4c9,3.6,16.7,8.4,23.1,14.6c6.5,6.1,11.5,13.3,15.2,21.4
		s5.5,16.7,5.5,25.8c0,9.1-1.8,17.7-5.5,25.8c-3.6,8.1-8.7,15.3-15.2,21.4c-6.5,6.1-14.2,11-23.1,14.6s-18.7,5.4-29.2,5.4h-53.8
		V2424.9z M570.3,2522.9c9.2,0,16.6-2.8,22.3-8.4c5.6-5.6,8.5-13.1,8.5-22.3s-2.8-16.6-8.5-22.3s-13.1-8.4-22.3-8.4h-9.6v61.4H570.3
		z"
          />
          <path class="sdd__path" d="M665.3,2424.9h43.2v134.4h-43.2V2424.9z" />
          <path
            class="sdd__path"
            d="M770,2424.9h49l49,134.4h-46.1l-7.7-25.9h-39.4l-7.7,25.9H721L770,2424.9z M805.5,2503.7l-10.6-37.4h-1
		l-10.6,37.4H805.5z"
          />
          <path
            class="sdd__path"
            d="M880.4,2424.9h43.2v94.1h55.7v40.3h-98.9V2424.9z"
          />
          <path
            class="sdd__path"
            d="M982.1,2492.1c0-9.9,1.9-19.1,5.7-27.7s9-16.2,15.7-22.6s14.7-11.4,24-15.2c9.3-3.7,19.4-5.6,30.4-5.6
		s21.2,1.9,30.4,5.6c9.3,3.7,17.3,8.8,24,15.2s12,13.9,15.7,22.6s5.7,17.9,5.7,27.7c0,9.9-1.9,19.1-5.7,27.7
		c-3.8,8.6-9,16.2-15.7,22.6s-14.7,11.5-24,15.2s-19.4,5.6-30.4,5.6s-21.2-1.9-30.4-5.6c-9.3-3.7-17.3-8.8-24-15.2
		s-12-13.9-15.7-22.6C984,2511.2,982.1,2502,982.1,2492.1z M1025.3,2492.1c0,4.6,0.8,8.9,2.5,12.9c1.7,4,4,7.4,6.9,10.4
		s6.4,5.2,10.4,6.9c4,1.7,8.3,2.5,12.9,2.5c4.6,0,8.9-0.8,12.9-2.5c4-1.7,7.4-4,10.4-6.9c2.9-2.9,5.2-6.4,6.9-10.4
		c1.7-4,2.5-8.3,2.5-12.9c0-4.6-0.8-8.9-2.5-12.9c-1.7-4-4-7.4-6.9-10.4c-3-2.9-6.4-5.2-10.4-6.9s-8.3-2.5-12.9-2.5
		c-4.6,0-8.9,0.8-12.9,2.5c-4,1.7-7.4,4-10.4,6.9c-3,2.9-5.2,6.4-6.9,10.4C1026.2,2483.2,1025.3,2487.5,1025.3,2492.1z"
          />
          <path
            class="sdd__path"
            d="M1221.2,2484.5h62.4v51.8c-4.3,5-9.5,9.5-15.6,13.4c-5.1,3.3-11.4,6.4-18.9,9.2c-7.5,2.8-16.2,4.2-26,4.2
		c-11,0-21.2-1.9-30.4-5.6s-17.3-8.8-24-15.2s-12-13.9-15.7-22.6c-3.8-8.6-5.7-17.9-5.7-27.7s1.9-19.1,5.7-27.7s9-16.2,15.7-22.6
		s14.7-11.4,24-15.2c9.3-3.7,19.4-5.6,30.4-5.6c9.9,0,18.4,1.3,25.7,4s13.4,5.7,18.2,9c5.8,3.8,10.6,8.1,14.6,12.9l-25.9,27.8
		c-2.3-2.8-5.1-5.4-8.3-7.7c-2.7-1.9-6-3.7-10-5.3c-4-1.6-8.4-2.4-13.4-2.4c-4.9,0-9.3,0.8-13.4,2.5s-7.6,4-10.6,6.9
		c-2.9,2.9-5.3,6.4-7,10.4s-2.6,8.3-2.6,12.9c0,4.6,0.8,8.9,2.5,12.9c1.7,4,4,7.4,6.9,10.4s6.4,5.2,10.4,6.9c4,1.7,8.3,2.5,12.9,2.5
		c3.6,0,6.7-0.3,9.3-0.9c2.6-0.6,4.8-1.2,6.6-2c2.1-0.8,3.8-1.7,5.2-2.9v-10.6h-23L1221.2,2484.5L1221.2,2484.5z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { ref, onMounted, onUpdated, nextTick } from "vue";

import BubbleThree from "./bubbles/BubbleThree";
export default {
  components: { BubbleThree },
  props: { allowScroll: Boolean, pin: Boolean },
  setup(props) {
    const allowScroll = ref(props.allowScroll);
    const pin = ref(props.pin);
    const scrollContainer = ref(null);
    const startAnimate = ref(false);

    let scrollT = null;

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
    });

    function killScrolltrigger() {
      //   console.log("killScrolltrigger");
      //   scrollT.kill();
      //   scrollT.refresh();
      //   tl.progress(1);
    }

    function defaultAnimation() {
      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        trigger: scrollContainer.value,
        start: "top 100%",
        onUpdate: (self) => {
          if (self.progress.toFixed(3) > 0) {
            startAnimate.value = true;
          }
        },
      });
    }
    function startAnimation() {
      let els = scrollContainer.value.querySelectorAll(".sdd__path");
      let bubble = scrollContainer.value.querySelectorAll(".sdd__bubble");
      let time = { time: 0 };

      tl.set(
        els,
        {
          y: 200,
          rotation: 0.02,
        },
        0
      ).set(bubble, { opacity: 0 }, 0);

      tl.to(
        els,
        {
          duration: 0.5,
          y: 0,
          rotation: 0,
          stagger: 0.1,
        },
        0
      )
        .to(bubble, { duration: 1, opacity: 1 }, 0)
        // .from(bubble, { duration: 3, y: "100%" }, 0)
        .to(time, { duration: 3 }, 0);

      let allowScrub = false;
      if (pin.value) {
        allowScrub = 0.2;
        startAnimate.value = false;
      } else {
        startAnimate.value = true;
      }

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        pin: pin.value, // pin the trigger element while active
        animation: tl,
        trigger: scrollContainer.value,
        scrub: allowScrub,
        start: "top 50%",
        end: () => {
          if (pin.value) {
            console.log("ahhahaha 2");
            return "100vh";
          } else {
            console.log("NOOOOO 2");
            return "top 0";
          }
        },
        onUpdate: (self) => {
          if (self.progress.toFixed(3) >= 1) {
            killScrolltrigger();
          } else if (self.progress.toFixed(3) > 0.5) {
            startAnimate.value = true;
          }
        },
      });
      scrollT.update();
      scrollT.refresh();
    }

    onMounted(() => {
      if (allowScroll.value) {
        startAnimation();
      } else {
        defaultAnimation();
      }
    });
    onUpdated(() => {});
    nextTick(() => {});
    return { scrollContainer, startAnimate };
  },
};
</script>

<style lang="scss"></style>
