<template>
  <div class="sdd__section" ref="scrollContainer">
    <!-- <AppLoveBrands /> -->
    <BubbleOne :startbubble="startAnimate" class="sdd__bubble" />
    <div class="sdd__wrapper">
      <!-- Generator: Adobe Illustrator 26.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg
        version="1.1"
        class="sdd__svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1103 142.1"
        style="enable-background:new 0 0 1103 142.1;"
        xml:space="preserve"
      >
        <g transform="translate(-349 -1999)">
          <path
            class="sdd__path"
            d="M374,2092.1c2.3,2.6,4.9,4.8,7.7,6.7c2.4,1.8,5.3,3.4,8.5,4.7s6.8,2,10.7,2c4.9,0,8.3-0.8,10.4-2.5
		c2-1.7,3.1-3.7,3.1-6.1c0-2.6-1.5-4.7-4.5-6.4c-3-1.7-6.8-3.5-11.2-5.2c-4.5-1.7-9.3-3.6-14.5-5.8c-5.2-2.1-10-4.8-14.5-8.1
		s-8.2-7.3-11.2-12.2c-3-4.9-4.5-10.9-4.5-18.1c0-5.5,1.2-10.8,3.6-15.9c2.4-5.1,6-9.6,10.6-13.5c4.6-3.9,10.2-7,16.9-9.3
		c6.6-2.3,14.2-3.5,22.7-3.5c8.7,0,16.2,1,22.4,3s11.4,4.2,15.5,6.6c4.7,2.8,8.8,6,12.1,9.6l-25,25.9c-1.4-1.8-3.1-3.4-5-4.8
		c-1.8-1.2-3.9-2.2-6.4-3.3c-2.5-1-5.4-1.5-8.7-1.5c-3.8,0-6.7,0.6-8.6,1.9s-2.9,2.9-2.9,4.8c0,2.6,1.5,4.7,4.5,6.4
		s6.8,3.5,11.2,5.2c4.5,1.7,9.3,3.6,14.5,5.8s10,4.8,14.5,8.1c4.5,3.3,8.2,7.3,11.2,12.2c3,4.9,4.5,10.9,4.5,18.1
		c0,5.8-1.3,11.3-3.8,16.6s-6.2,10-11,14.1c-4.8,4.1-10.7,7.4-17.6,9.8s-14.7,3.6-23.2,3.6c-9.2,0-17.3-1.2-24.3-3.6s-12.9-5-17.8-8
		c-5.6-3.3-10.5-7.2-14.6-11.5L374,2092.1z"
          />
          <path
            class="sdd__path"
            d="M500.7,2042.2h-35.5v-39.4h114.2v39.4h-35.5v95h-43.2V2042.2z"
          />
          <path
            class="sdd__path"
            d="M597.6,2002.8h66.2c7.4,0,14.2,1.2,20.5,3.7c6.2,2.5,11.6,5.9,16.1,10.2c4.5,4.3,8.1,9.4,10.6,15.3
		c2.5,5.9,3.7,12.2,3.7,18.8c0,7-1.1,13-3.4,17.8s-4.6,8.7-7.2,11.8c-3.2,3.6-6.7,6.5-10.6,8.8l25.9,48h-45.1l-19.2-38.4h-14.4v38.4
		h-43.2L597.6,2002.8z M659.1,2062.4c3.8,0,6.9-1.1,9.1-3.3c2.2-2.2,3.4-4.9,3.4-8.3s-1.1-6.1-3.4-8.3c-2.2-2.2-5.3-3.3-9.1-3.3
		h-18.2v23H659.1z"
          />
          <path
            class="sdd__path"
            d="M770.4,2002.8h49l49,134.4h-46.1l-7.7-25.9h-39.4l-7.7,25.9h-46.1L770.4,2002.8z M806,2081.6l-10.6-37.4h-1
		l-10.6,37.4H806z"
          />
          <path
            class="sdd__path"
            d="M894.3,2042.2h-35.5v-39.4H973v39.4h-35.5v95h-43.2L894.3,2042.2z"
          />
          <path
            class="sdd__path"
            d="M991.2,2002.8h106.6v35.5h-63.4v16.3h48v30.7h-48v16.3h65.3v35.5H991.2L991.2,2002.8z"
          />
          <path
            class="sdd__path"
            d="M1184.2,2062.4h62.4v51.8c-4.3,5-9.5,9.5-15.6,13.4c-5.1,3.3-11.4,6.4-18.9,9.2c-7.5,2.8-16.2,4.2-26,4.2
		c-11,0-21.2-1.9-30.4-5.6s-17.3-8.8-24-15.2s-12-13.9-15.7-22.6c-3.8-8.6-5.7-17.9-5.7-27.7s1.9-19.1,5.7-27.7s9-16.2,15.7-22.6
		s14.7-11.4,24-15.2c9.3-3.7,19.4-5.6,30.4-5.6c9.9,0,18.4,1.3,25.7,4s13.4,5.7,18.2,9c5.8,3.8,10.6,8.1,14.6,12.9l-25.9,27.8
		c-2.3-2.8-5.1-5.4-8.3-7.7c-2.7-1.9-6-3.7-10-5.3c-4-1.6-8.4-2.4-13.4-2.4c-4.9,0-9.3,0.8-13.4,2.5s-7.6,4-10.6,6.9
		c-2.9,3-5.3,6.4-7,10.4s-2.6,8.3-2.6,12.9c0,4.6,0.8,8.9,2.5,12.9c1.7,4,4,7.4,6.9,10.4s6.4,5.2,10.4,6.9c4,1.7,8.3,2.5,12.9,2.5
		c3.6,0,6.7-0.3,9.3-0.9c2.6-0.6,4.8-1.2,6.6-2c2.1-0.8,3.8-1.7,5.2-2.9v-10.6h-23L1184.2,2062.4L1184.2,2062.4z"
          />
          <path class="sdd__path" d="M1271.6,2002.8h43.2v134.4h-43.2V2002.8z" />
          <path
            class="sdd__path"
            d="M1343.6,2002.8h106.6v35.5h-63.4v16.3h48v30.7h-48v16.3h65.3v35.5h-108.5L1343.6,2002.8z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// import AppLoveBrands from "@/components/ui/buttons/AppLoveBrands.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { ref, onMounted, onUpdated, nextTick } from "vue";

import BubbleOne from "./bubbles/BubbleOne";
export default {
  components: { BubbleOne },
  props: { allowScroll: Boolean, pin: Boolean },
  setup(props) {
    const allowScroll = ref(props.allowScroll);
    const pin = ref(props.pin);

    console.log("pin ", pin.value);

    const scrollContainer = ref(null);
    const startAnimate = ref(false);

    let scrollT = null;

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
    });

    function killScrolltrigger() {
      // console.log("killScrolltrigger");
      // scrollT.kill();
      // scrollT.refresh();
      // tl.progress(1);
    }
    function defaultAnimation() {
      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        trigger: scrollContainer.value,
        start: "top 100%",
        onUpdate: (self) => {
          if (self.progress.toFixed(3) > 0) {
            startAnimate.value = true;
          }
        },
      });
    }
    function startAnimation() {
      let els = scrollContainer.value.querySelectorAll(".sdd__path");
      let bubble = scrollContainer.value.querySelectorAll(".sdd__bubble");
      // let trigger = scrollContainer.value.querySelector(".sdd__svg");

      let time = { time: 0 };

      tl.set(
        els,
        {
          y: 200,
          rotation: 0.02,
        },
        0
      ).set(bubble, { opacity: 0 }, 0);

      tl.to(
        els,
        {
          duration: 0.5,
          y: 0,
          rotation: 0,
          stagger: 0.1,
        },
        0
      )
        .to(bubble, { duration: 1, opacity: 1 }, 0)
        // .from(bubble, { duration: 3, y: "100%" }, 0)
        .to(time, { duration: 3 }, 0);

      let allowScrub = false;
      if (pin.value) {
        allowScrub = 0.2;
        startAnimate.value = false;
      } else {
        startAnimate.value = true;
      }

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        pin: pin.value, // pin the trigger element while active
        animation: tl,
        trigger: scrollContainer.value,
        scrub: allowScrub,
        start: "top 50%",
        end: () => {
          if (pin.value) {
            console.log("ahhahaha 2");
            return "100vh";
          } else {
            console.log("NOOOOO 2");
            return "top 0";
          }
        },
        onUpdate: (self) => {
          if (self.progress.toFixed(3) >= 1) {
            killScrolltrigger();
          } else if (self.progress.toFixed(3) > 0.5) {
            startAnimate.value = true;
          }
        },
      });

      scrollT.update();
      scrollT.refresh();
    }

    onMounted(() => {
      if (allowScroll.value) {
        startAnimation();
      } else {
        defaultAnimation();
      }
    });
    onUpdated(() => {
      if (scrollT) {
        scrollT.update();
        scrollT.refresh();
      }
    });
    nextTick(() => {
      if (scrollT) {
        scrollT.update();
        scrollT.refresh();
      }
    });

    return { scrollContainer, startAnimate };
  },
};
</script>

<style lang="scss"></style>
