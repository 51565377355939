<template>
  <div class="clock">{{ date.hours }}:{{ date.minutes }}</div>
</template>

<script>
import { reactive } from "@vue/reactivity";
import { onBeforeUnmount, onMounted } from "@vue/runtime-core";
export default {
  setup() {
    const date = reactive({
      hours: "blub",
      minutes: "blub",
      seconds: "blub",
    });
    let myTimer = null;

    function getDate() {
      var currentTime = new Date();
      date.hours = currentTime.getHours();
      date.minutes = currentTime.getMinutes();
      date.seconds = currentTime.getSeconds();
    }
    getDate();

    onMounted(() => {
      myTimer = setInterval(getDate, 1000);
    });
    onBeforeUnmount(() => {
      clearInterval(myTimer, false);
    });
    onMounted(() => {});

    return { date, myTimer };
  },
};
</script>

<style lang="scss">
.bubble__clock {
  margin-top: 9px;
  color: white;

  text-align: left;
  font: normal normal normal 16px/18px Helvetica Neue;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;

  &--left {
    padding-left: 30px;
  }
  &--right {
    text-align: right;
    padding-right: 30px;
  }
}
</style>
