<template>
  <div class="sdd__section" ref="scrollContainer">
    <BubbleTwo :startbubble="startAnimate" class="sdd__bubble" />
    <div class="sdd__wrapper">
      <!-- Generator: Adobe Illustrator 26.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
      <svg
        version="1.1"
        class="sdd__svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1103 142.1"
        style="enable-background:new 0 0 1103 142.1;"
        xml:space="preserve"
      >
        <g transform="translate(-349.001 -2210.096)" class="st2">
          <path
            class="sdd__path"
            d="M524.2,2213.9H578c10.5,0,20.2,1.8,29.2,5.4c9,3.6,16.7,8.4,23.1,14.6c6.5,6.1,11.5,13.3,15.2,21.4
            s5.5,16.7,5.5,25.8c0,9.1-1.8,17.7-5.5,25.8c-3.7,8.1-8.7,15.3-15.2,21.4c-6.5,6.1-14.2,11-23.1,14.6s-18.7,5.4-29.2,5.4h-53.8
            V2213.9z M577,2311.9c9.2,0,16.6-2.8,22.3-8.4c5.6-5.6,8.5-13.1,8.5-22.3s-2.8-16.6-8.5-22.3s-13.1-8.4-22.3-8.4h-9.6v61.4H577z"
          />
          <path
            class="sdd__path"
            d="M672,2213.9h106.6v35.5h-63.4v16.3h48v30.7h-48v16.3h65.3v35.5H672L672,2213.9z"
          />
          <path
            class="sdd__path"
            d="M815.1,2303.2c2.3,2.6,4.9,4.8,7.7,6.7c2.4,1.8,5.3,3.4,8.5,4.7s6.8,2,10.7,2c4.9,0,8.3-0.8,10.4-2.5
            c2-1.7,3.1-3.7,3.1-6.1c0-2.6-1.5-4.7-4.5-6.4c-3-1.7-6.8-3.5-11.2-5.2c-4.5-1.7-9.3-3.6-14.5-5.8c-5.2-2.1-10-4.8-14.5-8.1
            s-8.2-7.3-11.2-12.2c-3-4.9-4.5-10.9-4.5-18.1c0-5.5,1.2-10.8,3.7-15.9c2.4-5.1,6-9.6,10.6-13.5c4.6-3.9,10.2-7,16.9-9.3
            c6.7-2.3,14.2-3.5,22.7-3.5c8.7,0,16.2,1,22.4,3s11.4,4.2,15.5,6.6c4.7,2.8,8.8,6,12.1,9.6l-25,25.9c-1.4-1.8-3.1-3.4-5-4.8
            c-1.8-1.1-3.9-2.2-6.4-3.3c-2.5-1-5.4-1.5-8.7-1.5c-3.8,0-6.7,0.6-8.6,1.9s-2.9,2.9-2.9,4.8c0,2.6,1.5,4.7,4.5,6.4
            s6.8,3.5,11.2,5.2c4.5,1.7,9.3,3.6,14.5,5.8s10,4.8,14.5,8.1c4.5,3.3,8.2,7.3,11.2,12.2c3,4.9,4.5,10.9,4.5,18.1
            c0,5.8-1.3,11.3-3.8,16.6s-6.2,10-11,14.1c-4.8,4.1-10.7,7.4-17.6,9.8s-14.7,3.6-23.2,3.6c-9.2,0-17.3-1.2-24.3-3.6s-12.9-5-17.8-8
            c-5.6-3.3-10.5-7.2-14.6-11.5L815.1,2303.2z"
          />
          <path class="sdd__path" d="M920.7,2213.9h43.2v134.4h-43.2V2213.9z" />
          <path
            class="sdd__path"
            d="M1058.9,2273.5h62.4v51.8c-4.3,5-9.5,9.5-15.5,13.4c-5.1,3.3-11.4,6.4-18.9,9.2c-7.5,2.8-16.2,4.2-26,4.2
            c-11,0-21.2-1.9-30.4-5.6c-9.3-3.7-17.3-8.8-24-15.2s-12-13.9-15.7-22.6c-3.8-8.6-5.7-17.9-5.7-27.7s1.9-19.1,5.7-27.7
            s9-16.2,15.7-22.6s14.7-11.4,24-15.2c9.3-3.7,19.4-5.6,30.4-5.6c9.9,0,18.4,1.3,25.7,4c7.3,2.7,13.4,5.7,18.2,9
            c5.8,3.8,10.6,8.1,14.6,12.9l-25.9,27.8c-2.3-2.8-5.1-5.4-8.3-7.7c-2.7-1.9-6-3.7-10-5.3c-4-1.6-8.4-2.4-13.4-2.4
            c-4.9,0-9.3,0.8-13.4,2.5s-7.6,4-10.6,6.9c-3,2.9-5.3,6.4-7,10.4s-2.6,8.3-2.6,12.9c0,4.6,0.8,8.9,2.5,12.9c1.7,4,4,7.4,6.9,10.4
            c2.9,3,6.4,5.2,10.4,6.9c4,1.7,8.3,2.5,12.9,2.5c3.6,0,6.7-0.3,9.3-0.9c2.6-0.6,4.8-1.2,6.6-2c2.1-0.8,3.8-1.7,5.2-2.9v-10.6h-23
            L1058.9,2273.5L1058.9,2273.5z"
          />
          <path
            class="sdd__path"
            d="M1146.3,2213.9h37.4l49.9,64.3v-64.3h43.2v134.4h-37.4l-49.9-64.3v64.3h-43.2L1146.3,2213.9z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { ref, onMounted, onUpdated, nextTick } from "vue";

import BubbleTwo from "./bubbles/BubbleTwo";
export default {
  components: { BubbleTwo },

  props: { allowScroll: Boolean, pin: Boolean },
  setup(props) {
    const allowScroll = ref(props.allowScroll);
    const pin = ref(props.pin);
    const scrollContainer = ref(null);
    const startAnimate = ref(false);

    let scrollT = ref(null);

    let tl = gsap.timeline({
      // yes, we can add it to an entire timeline!
    });

    function killScrolltrigger() {
      // console.log("killScrolltrigger");
      // scrollT.kill();
      // scrollT.refresh();
      // tl.progress(1);
    }

    function defaultAnimation() {
      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        trigger: scrollContainer.value,
        start: "top 100%",
        onUpdate: (self) => {
          if (self.progress.toFixed(3) > 0) {
            startAnimate.value = true;
          }
        },
      });
    }
    function startAnimation() {
      let els = scrollContainer.value.querySelectorAll(".sdd__path");
      let bubble = scrollContainer.value.querySelectorAll(".sdd__bubble");
      let time = { time: 0 };

      tl.set(
        els,
        {
          y: 200,
          rotation: 0.02,
        },
        0
      ).set(bubble, { opacity: 0 }, 0);

      tl.to(
        els,
        {
          duration: 0.5,
          y: 0,
          rotation: 0,
          stagger: 0.1,
        },
        0
      )
        .to(bubble, { duration: 1, opacity: 1 }, 0)
        // .from(bubble, { duration: 3, y: "100%" }, 0)
        .to(time, { duration: 3 }, 0);

      let allowScrub = false;
      if (pin.value) {
        allowScrub = 0.2;
        startAnimate.value = false;
      } else {
        startAnimate.value = true;
      }

      scrollT = ScrollTrigger.create({
        // scroller: "#main__content",
        pin: pin.value, // pin the trigger element while active
        animation: tl,
        trigger: scrollContainer.value,
        scrub: allowScrub,
        start: "top 50%",
        end: () => {
          if (pin.value) {
            console.log("ahhahaha 2");
            return "100vh";
          } else {
            console.log("NOOOOO 2");
            return "top 0";
          }
        },
        onUpdate: (self) => {
          if (self.progress.toFixed(3) >= 1) {
            killScrolltrigger();
          } else if (self.progress.toFixed(3) > 0.5) {
            startAnimate.value = true;
          }
        },
      });
    }

    onMounted(() => {
      if (allowScroll.value) {
        startAnimation();
      } else {
        defaultAnimation();
      }
    });
    onUpdated(() => {});
    nextTick(() => {});

    return { scrollContainer, startAnimate, scrollT };
  },
};
</script>
<style lang="scss"></style>
